import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "",
  description = ""
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: "We partner exclusively with vendors that provide top-notch security infrastructure, ensuring your data is protected with the highest standards of encryption and security protocols. Our system continuously monitors for vulnerabilities and employs advanced threat detection to keep your information safe."
    },
    {
      imageSrc: SupportIconImage,
      title: "24/7 Support",
      description: "Our dedicated support team is available around the clock to assist you with any issues or questions, ensuring continuous monitoring and peace of mind. Whether you need help setting up alerts for Facebook groups or understanding the reports we send, our experts are always ready to provide prompt and effective support."
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Customizable Monitoring",
      description: "Our service is fully customizable to meet your specific needs, allowing you to tailor monitoring settings for Facebook groups like 'Are We Dating the Same Guy.' Choose exactly which aspects to monitor and how frequently you receive updates, giving you complete control over your reputation management."
    },
    {
      imageSrc: ReliableIconImage,
      title: "Reliable Service",
      description: "Our monitoring service is built on a robust infrastructure, ensuring you can consistently track mentions in relevant Facebook groups. With our reliable systems, you can trust that your identity monitoring is always active and dependable."
    },
    {
      imageSrc: FastIconImage,
      title: "Real-Time Alerts",
      description: "Experience fast performance with our optimized systems, designed to quickly scan and deliver updates on any new mentions of you in monitored Facebook groups. Our real-time alerts mean you get notified instantly, allowing you to respond without delay."
    },
    {
      imageSrc: SimpleIconImage,
      title: "User-Friendly Interface",
      description: "Our intuitive platform makes it simple to set up and manage your monitoring preferences for Facebook groups. With straightforward navigation and clear reports, you can easily track your mentions and maintain control over your online reputation with minimal effort."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Loremmmm ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
